import React from 'react';

const Program12 = () => (
    <div className="block">
        <p>
            Om de Roadmap gerealiseerd te krijgen, is een breed draagvlak voor haar ambities cruciaal. Dit vereist een permanente, stadsbrede sensibilisering over het belang van klimaatmaatregelen. Daarbij is er niet enkel aandacht nodig voor de uitdagingen en de reeds behaalde successen, maar ook voor de meerwaarde van een klimaatneutrale stad voor iedereen in de maatschappij: overheden, bedrijven, kennisinstellingen, burgers en investeerders. Er moet ingezet worden op goed toegankelijke informatie voor iedereen die zelf actie wil ondernemen, maar ook op doelgroepgerichte communicatie die meer mensen bij het klimaatverhaal betrekt en tot actie aanzet. Jongeren, de Leuvenaars van de toekomst, krijgen daarbij bijzondere aandacht. 
        </p>
        <p>
            Vzw Leuven 2030 heeft de ambitie om verder uit te groeien tot een stadsbrede organisatie, die de hele Leuvense gemeenschap aanspreekt. We verzamelden reeds heel wat Leuvense actoren in een breed maatschappelijk samenwerkingsverband, maar hebben de ambitie om verder te groeien op vlak van capaciteit, middelen en netwerk om nog meer bekendheid te vergaren. We willen onze leden sterker aanmoedigen om concrete klimaatplannen op te maken of te actualiseren, deze in te schrijven in de ambities uit deze Roadmap en er ook consequent naar te handelen. 
        </p>
        <p>
            De klimaattransitie is pas écht duurzaam als ze iedereen aan boord kan nemen en houden, ook de maatschappelijk kwetsbare groepen. De sociale rechtvaardigheid van alle werven uit deze Roadmap is essentieel en moet voortdurend getoetst worden. Waar nodig zijn bijkomende maatregelen nodig, bijvoorbeeld om de betaalbaarheid van woningen te garanderen en mobiliteits- of energiearmoede tegen te gaan.
        </p>
    </div>
);

export default Program12;
