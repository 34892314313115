import React from 'react';

const Program8 = () => (
    <div className="block">
        <p>
            Voor voeding is er een sterke overlap tussen duurzaamheid en gezondheid. Gezonde voeding, met een verantwoorde verhouding tussen dierlijke en plantaardige voeding, heeft ook een lagere klimaatimpact. Meer inzetten op seizoensgebonden voeding, die lokaal geproduceerd wordt waar dat kan op een energiezuinige manier (zowel door de professionele landbouwers als via collectieve projecten), is dan ook een must. Via samenwerkingen met KU Leuven en andere kennisinstellingen en organisaties kunnen we innovatie voor duurzame landbouw en voeding stimuleren.
        </p>
        <p>
            Om dit voedingspatroon maximaal te promoten, moeten we naast inzetten op campagnes ook een omgeving creëren die betaalbare, gezonde en duurzame voeding stimuleert in supermarkten, scholen, bedrijven, zorginstellingen,… . Daarnaast is het belangrijk om via charters en actieplannen, voor de grootste actoren uit de sector, voedselverspilling en verpakkingsmateriaal voor voedingsproducten aan te pakken. 
        </p>
    </div>
);

export default Program8;
