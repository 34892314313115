import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'intersection-observer';

import React from 'react';
import { render } from 'react-snapshot';

import data from './data';

import './sitewide';

import './index.scss';

import App from './App';

// TODO
window.requestAnimationFrame = function(callback) {
    setTimeout(callback, 0);
};

render(
    <App data={data} />,
    document.getElementById('root')
);
