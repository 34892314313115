import React, { useState, useEffect, useRef } from 'react';

import Profile from './Profile';

const Profiles = ({ id, title, data }) => {
    const { profiles } = data;

    const [ready, setReady] = useState(false);
    const profilesRef = useRef(null);

    useEffect(() => {
        const io = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setReady(true);
                    io.disconnect();
                }
            });
        }, {
            threshold: .1,
        });

        io.observe(profilesRef.current);
    }, [profilesRef]);
    
    return (
        <div id={id} className={`page profiles${ready ? ' is-ready' : ''}`} ref={profilesRef}>
            <div className="page__inner" data-desktop-observer-target data-id={id}>
                <h1
                    className="headline-1 is-blue is-center"
                    dangerouslySetInnerHTML={{__html: title}}
                />
                <ul className="profiles__list">
                    {profiles.map(profile => (
                        <li>
                            <Profile key={profile.id} {...profile} />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Profiles;
