import data from '../data';

export const detectIE = () => {
    const ua = window.navigator.userAgent;

    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }
  
    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        const rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }
  
    // const edge = ua.indexOf('Edge/');
    // if (edge > 0) {
    //     // Edge (IE 12+) => return version number
    //     return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    // }
  
    // other browser
    return false;
}

export const padNumber = (number) => {
    let string = String(number);

    if (string.length < 2) {
        string = `0${string}`;
    }

    return string;
}

export const getProgramByID = (id) => {
    const programs = data.themes.map(theme => (
        [...theme.items]
    ));

    const flatPrograms = [].concat(...programs);

    return flatPrograms.find(item => item.id === id);
}

export const getProfilesByProgramID = (id) => {
    return data.profiles.filter(profile => profile.programs && profile.programs.includes(id))
}
