import React from 'react';

import { Link } from 'react-router-dom';

import { padNumber } from '../../helpers';

const TocItem = ({ number, url, title }) => (
    <Link className="toc-index__item" to={url}>
        <span className="toc-index__title">
            {title}
        </span>
        <span className="toc-index__number">
            <svg className="toc-index__number-value" width="110" height="110" viewBox="0 0 110 110">
                <mask id={`toc-mask-${number}`}>
                    <text x="50" y="78" fill="#ffffff" textAnchor="middle">
                        {padNumber(number)}
                    </text>
                </mask>
                <g mask={`url(#toc-mask-${number})`}>
                    <rect width="110" height="110" fill="#1E5941" />
                </g>
                <g className="toc-index__number-overlay" mask={`url(#toc-mask-${number})`}>
                    <path fill="#AEC311" d="M64.2075 44.8176L0 86.127V110l110.2509-.2437L110 17.6922z"/><path fill="#009DAF" d="M5.078 0l59.1298 44.8172L110 17.6922V0z"/><path fill="#00583D" d="M5.078 0H0v86.127l64.2079-41.3099z"/>
                </g>
            </svg>

            <svg width="214" height="23" className="toc-index__number-shape">
                <use xlinkHref="#number-shape"></use>
            </svg>
        </span>
    </Link>
);

export default TocItem;
