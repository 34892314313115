import React from 'react';

const Program4 = () => (
    <div className="block">
        <p>
            Als we werk willen maken van de omslag naar duurzame mobiliteit, is het nodig om de behoefte aan verplaatsingen te doen dalen. Door efficiënter om te gaan met de ruimte in de Leuvense stadsregio kunnen méér mensen in of dichtbij de stad wonen en in nabijheid van de belangrijkste diensten (school, winkels, ontspanning, horeca),  hetgeen hun vraag naar mobiliteit beperkt. Grote woonprojecten en bedrijven worden voorzien op plaatsen waar verschillende, zo duurzaam mogelijke transportmogelijkheden beschikbaar zijn: trein, bus, (deel)fiets, …  Daarnaast wordt er een goede verweving van verschillende functies nagestreefd zodat stadswijken met énkel een woonfunctie zonder ondersteunende functies in de nabije omgeving worden vermeden.
        </p>
    </div>
);

export default Program4;
