import React from 'react';

const Program3 = () => (
    <div className="block">
        <p>
            Elk nieuw gebouw dat vandaag wordt gebouwd en nog gebruik maakt van de verbranding van fossiele brandstoffen (zoals gas) om haar verwarming en koeling en haar elektriciteitsgebruik te garanderen, maakt de uitdaging om te komen tot een klimaatneutrale stad alleen maar groter. In het ideale geval is alle nieuwbouw in staat om het comfort te garanderen onafhankelijk van fossiele brandstoffen. Daarom is het essentieel dat alle nieuwbouw wordt gebouwd met de meest duurzame en energiezuinigste systemen voor isolatie, luchtdichtheid, ventilatie, verwarming en koeling. De vraag naar verwarming, koeling en elektriciteit die in een efficiënt en goed geïsoleerd gebouw overblijft, kunnen we dan invullen met hernieuwbare energie.
        </p>
    </div>
);

export default Program3;
