import React from 'react';

const Program11 = () => (
    <div className="block">
        <p>
            Leuven 2030 intensiveert de samenwerking met huidige en nieuwe stakeholders om de Leuvense klimaattransitie te versnellen. Een samenwerking met de buurgemeenten en zelfs de regio rond bovenlokale klimaatthema’s zoals mobiliteit, energie en voeding is zeer aangeraden.
        </p>
        <p>
            Vzw Leuven 2030 ondersteunt de opstart of de opschaling van de programma’s en bijhorende klimaatwerven in de Roadmap, door programmaverantwoordelijken en werfleiders in te zetten en stakeholders aan te spreken en samen te brengen. Daarnaast zal Leuven 2030 zelf ook een aantal programma’s en klimaatwerven trekken. Erover waken dat deze talrijke programmaverantwoordelijken en teams over de nodige informatie beschikken, de nodige ruimte vinden, ontzorgd worden, ... is een grote uitdaging op zich die onder dit programma wordt aangegaan.
        </p>
        <p>
            De klimaattransitie heeft een grote financiële impact. Investeringen op korte termijn voorkomen dat de kosten in de toekomst nog veel hoger zullen oplopen. Een ruwe inschatting spreekt over een nodige jaarlijkse investering van zo’n 350 miljoen euro  om van Leuven een klimaatneutrale stad te maken tegen 2050. Dit vergt geld dat er in grote mate al ís, maar niet gespendeerd wordt aan wat een klimaattoekomst dichterbij brengt. Daarnaast kunnen verschillende instrumenten worden ingezet om de investeringen te helpen dragen: een Leuvens klimaatfonds, ESCO’s, energiecoöperaties, divesteringen, …
        </p>
        <p>
            De klimaattransitie zorgt bovendien voor een nieuwe economische dynamiek doordat bestaande en nieuwe innovatieve bedrijven inspelen op de klimaatuitdagingen. 
        </p>
    </div>
);

export default Program11;
