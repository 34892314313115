import React from 'react';

import Glossary from '../../components/glossary/Glossary';

const Program5 = () => (
    <div className="block">
        <p>
            Een belangrijke pijler voor de omslag naar duurzame vervoerswijzen, is de realisatie van een modal shift. Modal shift is de term voor verandering van vervoerswijze, bijvoorbeeld van transport met de auto naar transport met de fiets. Om het doel van een klimaatneutrale stad waar te maken, is een verdubbeling van het aantal fietsers en gebruikers van openbaar vervoer tussen 2010 en 2030 nodig. Op die manier reduceren we het aantal autoverplaatsingen met 20%.
        </p>
        <p>
            De omslag naar een duurzame mobiliteit is erg complex want het vraagt een gedragsverandering van een groot deel van de Leuvense inwoners en bezoekers, maar ook van leveranciers en transporteurs. De betrokken overheden dienen hiervoor dan ook belangrijke maatregelen te nemen op korte termijn. Denk maar aan verkeersluwe centra, verkeersveilige wijken en schoolomgevingen; veilige en comfortabele fietsinfrastructuur; een lage-emissiezone, duurzaam goederentransport en duurzame stadsdistributie;…
        </p>
        <p>
            Tussen <strong>5 en 10% van de Leuvense <Glossary
                term="CO₂e-uitstoot"
                definition="De uitstoot van broeikasgassen wordt regelmatig uitgedrukt in CO₂-equivalenten (CO₂e). CO₂ is immers niet het enige klimaatverstorende broeikasgas. Met de rekeneenheid CO₂e wordt de impact van de andere broeikasgassen vertaald naar CO₂-impact, zodat alles in één cijfer kan worden weergegeven."
            /></strong> is afkomstig van onze <strong>vliegverplaatsingen</strong>. Ook hier is een grote CO₂e-winst te boeken.
        </p>
    </div>
);

export default Program5;
