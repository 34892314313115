import Intro from './components/intro/Intro';
import TableOfContents from './components/toc/TableOfContents';
import Explainer from './components/explainer/Explainer';
import Colofon from './components/colofon/Colofon';
import Profiles from './components/profiles/Profiles';

import Program1 from './content/programs/Program1';
import Program2 from './content/programs/Program2';
import Program3 from './content/programs/Program3';
import Program4 from './content/programs/Program4';
import Program5 from './content/programs/Program5';
import Program6 from './content/programs/Program6';
import Program7 from './content/programs/Program7';
import Program8 from './content/programs/Program8';
import Program9 from './content/programs/Program9';
import Program10 from './content/programs/Program10';
import Program11 from './content/programs/Program11';
import Program12 from './content/programs/Program12';
import Program13 from './content/programs/Program13';

export default {
    pagesBefore: [
        {
            id: "intro",
            component: Intro,
            tocTitle: "Intro",
            tocIcon: "icon-home",
        },
        {
            id: "inhoud",
            component: TableOfContents,
        },
        {
            id: "verantwoordelijken",
            tocTitle: "Programmaverantwoordelijken",
            tocIcon: "icon-profile",
            title: "Naar een klimaatneutraal Leuven met deze programmaverant&shy;woordelijken",
            component: Profiles,
        },
    ],
    pagesAfter: [
        {
            id: "waarom-deze-roadmap",
            tocTitle: "Waarom deze Roadmap en hoe zetten we deze om in de praktijk?",
            tocIcon: "icon-question",
            title: "Waarom deze Roadmap en hoe zetten we deze om in de praktijk?",
            component: Explainer,
        },
        {
            id: "colofon",
            tocTitle: "Colofon",
            tocIcon: "icon-info",
            title: "Colofon",
            component: Colofon,
        },
    ],
    profiles: [
        {
            id: "anne",
            name: "Anne Goidts",
            image: "anne.png",
            email: "anne.goidts@leuven2030.be",
            programs: ['woningen-renoveren'],
        },
        {
            id: "caroline",
            name: "Caroline Huyghe",
            image: "caroline.png",
            email: "caroline.huyghe@leuven2030.be",
            programs: ['duurzaam-en-gezond-eten'],
        },
        {
            id: "dries",
            name: "Dries Vleugels",
            image: "dries.png",
            email: "dries.vleugels@leuven2030.be",
            programs: ['woningen-renoveren', 'groene-energie-opwekken'],
        },
        {
            id: "ewoud",
            name: "Ewoud Monbaliu",
            image: "ewoud.png",
            email: "ewoud.monbaliu@leuven2030.be",
            programs: ['iedereen-betrekken-in-klimaattransitie'],
        },
        {
            id: "ilse",
            name: "Ilse Premereur",
            image: "ilse.jpg",
            email: "ilse.premereur@leuven2030.be",
            programs: ['groene-energie-opwekken'],
        },
        {
            id: "jan",
            name: "Jan Aerts",
            image: "jan.png",
            email: "jan.aerts@leuven2030.be",
            programs: ['niet-woningen-renoveren', 'nieuwe-gebouwen-klimaatneutraal-bouwen'],
        },
        {
            id: "jessie",
            name: "Jessie Van Couwenberghe",
            image: "jessie.png",
            email: "jessie.vancouwenberghe@leuven2030.be",
            programs: ['leuven-circulair'],
        },
        {
            id: "johan",
            name: "Johan Van Reeth",
            image: "johan.png",
            email: "johan.vanreeth@leuven2030.be",
            programs: ['duurzame-vervoerswijzen', 'leuvense-vervoer-vergroenen'],
        },
        {
            id: "luc",
            name: "Luc Alaerts",
            image: "luc.png",
            email: "luc.alaerts@leuven2030.be",
            programs: ['kennisopbouw-en-monitoring'],
        },
        {
            id: "michele",
            name: "Michèle Jacobs",
            image: "michele.png",
            email: "michele.jacobs@leuven2030.be",
            programs: ['iedereen-betrekken-in-klimaattransitie'],
        },
        {
            id: "stephan",
            name: "Stephan Reniers",
            image: "stephan.png",
            email: "stephan.reniers@leuven2030.be",
            programs: ['efficienter-omgaan-met-de-ruimte'],
        },
        {
            id: "stijn",
            name: "Stijn Adam",
            image: "stijn.png",
            email: "stijn.adam@leuven2030.be",
            programs: ['groene-energie-opwekken'],
        },
        {
            id: "tim",
            name: "Tim Asperges",
            image: "tim.png",
            email: "tim.asperges@leuven2030.be",
            programs: ['duurzame-vervoerswijzen', 'leuvense-vervoer-vergroenen'],
        },
        {
            id: "wim",
            name: "Wim Verheyden",
            image: "wim.png",
            email: "wim.verheyden@leuven2030.be",
            programs: ['groene-en-veerkrachtige-ruimte'],
        },
        {
            id: "katrien",
            name: "Katrien Rycken",
            image: "katrien.png",
            description: "Roadmap manager",
            email: "katrien.rycken@leuven2030.be",
            programs: ['governance-en-financieringen']
        },
    ],
    themes: [
        {
            id: "renoveren-bouwen",
            title: "Renoveren & Bouwen",
            items: [
                {
                    number: 1,
                    id: "woningen-renoveren",
                    title: "Woningen renoveren",
                    component: Program1,
                    file: {
                        size: "956kb",
                        pages: "14",
                    },
                    subitems: [
                        {
                            id: "1",
                            title: "Benaderen van private eigenaars van woongebouwen",
                        },
                        {
                            id: "2",
                            title: "Geven van renovatieadviezen voor woongebouwen",
                        },
                        {
                            id: "3",
                            title: "Aanpassen regelgeving over & financiering van energierenovatie woongebouwen",
                        },
                        {
                            id: "4",
                            title: "Renovatie van sociale huurwoningen",
                        },
                        {
                            id: "5",
                            title: "Collectieve woningrenovaties",
                        },
                        {
                            id: "6",
                            title: "Renovatie van private woongebouwen op individuele basis",
                        },
                        {
                            id: "7",
                            title: "Energieverbruik installaties en apparaten in woongebouwen aanpakken",
                        }
                    ],
                    parallax: [
                        {
                            image: 'P1-layer0.jpg',
                            depth: 0,
                        },
                        {
                            image: 'P1-layer1.jpg',
                            depth: 0.08,
                        },
                        {
                            image: 'P1-layer2.png',
                            depth: 0.15,
                        },
                        {
                            image: 'P1-layer3.png',
                            depth: 0.2,
                        }
                    ],
                    copyright: "",
                },
                {
                    number: 2,
                    id: "niet-woningen-renoveren",
                    title: "Niet-woningen renoveren",
                    component: Program2,
                    file: {
                        size: "651kb",
                        pages: "12",
                    },
                    subitems: [
                        {
                            id: "8",
                            title: "Renovatie patrimonium van de grote gebouweigenaars",
                        },
                        {
                            id: "9",
                            title: "Renovatie van de (andere) energie-intensieve gebouwen",
                        },
                        {
                            id: "10",
                            title: "Pilootwijken voor de niet-residentiële sector",
                        },
                        {
                            id: "11",
                            title: "Benadering overige niet-residentiële gebouwen",
                        },
                        {
                            id: "12",
                            title: "Renovatieadviezen voor overige niet-residentiële gebouwen",
                        },
                        {
                            id: "13",
                            title: "Aangepaste regelgeving en financiering voor energierenovatie van niet-residentiële gebouwen",
                        },
                        {
                            id: "14",
                            title: "Individuele niet-residentiële gebouwen renoveren",
                        },
                        {
                            id: "15",
                            title: "Aanpassing vaste apparaten in grote niet-woongebouwen",
                        },
                        {
                            id: "16",
                            title: "Aanpassen gedrag & gebruik apparaten in de niet-residentiële sector",
                        }
                    ],
                    parallax: [
                        {
                            image: 'P2-layer0.jpg',
                            depth: 0,
                        },
                        {
                            image: 'P2-layer1.jpg',
                            depth: 0.08,
                        },
                        {
                            image: 'P2-layer2.png',
                            depth: 0.15,
                        },
                        {
                            image: 'P2-layer3.png',
                            depth: 0.2,
                        },
                    ],
                    copyright: "Geert Vanden Wijngaert",
                },
                {
                    number: 3,
                    id: "nieuwe-gebouwen-klimaatneutraal-bouwen",
                    title: "Nieuwe gebouwen klimaatneutraal bouwen",
                    component: Program3,
                    file: {
                        size: "711kb",
                        pages: "8",
                    },
                    subitems: [
                        {
                            id: "17",
                            title: "Regelgeving voor nieuwe gebouwen",
                        },
                        {
                            id: "18",
                            title: "BEN-nieuwbouw slim ontwerpen",
                        },
                        {
                            id: "19",
                            title: "Duurzame en klimaatneutrale bouwmaterialen",
                        },
                    ],
                    parallax: [
                        {
                            image: 'P3-layer0.jpg',
                            depth: 0,
                        },
                        {
                            image: 'P3-layer1.jpg',
                            depth: 0.08,
                        },
                        {
                            image: 'P3-layer2.png',
                            depth: 0.15,
                        },
                        {
                            image: 'P3-layer3.png',
                            depth: 0.06,
                        },
                    ],
                    copyright: "360 Architecten i.o.v. BotaniCo",
                },
            ],
        },
        {
            id: "duurzame-mobiliteit",
            title: "Duurzame mobiliteit",
            items: [
                {
                    number: 4,
                    id: "efficienter-omgaan-met-de-ruimte",
                    title: "Efficiënter omgaan met de ruimte in de Leuvense stadsregio",
                    component: Program4,
                    file: {
                        size: "766kb",
                        pages: "10",
                    },
                    subitems: [
                        {
                            id: "20",
                            title: "Het bestaande patrimonium beter benutten",
                        },
                        {
                            id: "21",
                            title: "Stadsregionaal ruimtelijk beleid",
                        },
                        {
                            id: "22",
                            title: "Verdichting en verweving",
                        },
                        {
                            id: "23",
                            title: "Clusteren van attractoren rond OV-knopen",
                        },
                        {
                            id: "24",
                            title: "Afbouw van de periferisering in Oost-Brabant",
                        },
                        {
                            id: "25",
                            title: "Activiteiten anders organiseren",
                        },
                    ],
                    parallax: [
                        {
                            image: 'P4-layer0.jpg',
                            depth: 0,
                        },
                        {
                            image: 'P4-layer1.jpg',
                            depth: 0.08,
                        },
                        {
                            image: 'P4-layer2.png',
                            depth: 0.07,
                        },
                        {
                            image: 'P4-layer3.png',
                            depth: 0.15,
                        },
                        {
                            image: 'P4-layer4.png',
                            depth: 0.1,
                        },
                        {
                            image: 'P4-layer5.png',
                            depth: 0.08,
                        },
                    ],
                    copyright: "Milo Profi",
                },
                {
                    number: 5,
                    id: "duurzame-vervoerswijzen",
                    title: "Omslag naar duurzame vervoerswijzen",
                    component: Program5,
                    file: {
                        size: "538kb",
                        pages: "16",
                    },
                    subitems: [
                        {
                            id: "26",
                            title: "Uitbouw van een geïntegreerd mobiliteitsbeleid",
                        },
                        {
                            id: "27",
                            title: "Autoluwe binnenstad, wijken en schoolomgevingen",
                        },
                        {
                            id: "28",
                            title: "Faciliteren van verplaatsingen te voet",
                        },
                        {
                            id: "29",
                            title: "Vergroten van het fietsgebruik",
                        },
                        {
                            id: "30",
                            title: "Vergroten van het OV-gebruik",
                        },
                        {
                            id: "31",
                            title: "Deelmobiliteit uitbouwen",
                        },
                        {
                            id: "32",
                            title: "Autoverkeer ontmoedigen en het parkeerbeleid verscherpen",
                        },
                        {
                            id: "33",
                            title: "Duurzamer goederentransport faciliteren en stimuleren",
                        },
                        {
                            id: "34",
                            title: "Afbouw van de vliegverplaatsingen",
                        },
                        {
                            id: "35",
                            title: "Monitoring van de verplaatsingen",
                        },
                    ],
                    parallax: [
                        {
                            image: 'P5-layer0.jpg',
                            depth: 0,
                        },
                        {
                            image: 'P5-layer1.jpg',
                            depth: 0.08,
                        },
                        {
                            image: 'P5-layer2.png',
                            depth: 0.2,
                        },
                        {
                            image: 'P5-layer3.png',
                            depth: 0.15,
                        },
                        {
                            image: 'P5-layer4.png',
                            depth: 0.15,
                        },
                    ],
                    copyright: "Toerisme Leuven",
                },
                {
                    number: 6,
                    id: "leuvense-vervoer-vergroenen",
                    title: "Het Leuvense vervoer vergroenen",
                    component: Program6,
                    file: {
                        size: "422kb",
                        pages: "6",
                    },
                    subitems: [
                        {
                            id: "36",
                            title: "Netwerk van elektrische laadpunten",
                        },
                        {
                            id: "37",
                            title: "Elektrische personenwagens",
                        },
                        {
                            id: "38",
                            title: "Vergroening van bussen en vrachtwagens",
                        },
                    ],
                    parallax: [
                        {
                            image: 'P6-layer0.jpg',
                            depth: 0,
                        },
                        {
                            image: 'P6-layer1.jpg',
                            depth: 0.077,
                        },
                        {
                            image: 'P6-layer2.png',
                            depth: 0.085,
                        },
                        {
                            image: 'P6-layer3.png',
                            depth: 0.1,
                        }
                    ],
                    copyright: "De Lijn",
                }
            ]
        },
        {
            id: "groene-energie",
            title: "Groene energie",
            items: [
                {
                    number: 7,
                    id: "groene-energie-opwekken",
                    title: "Groene energie opwekken",
                    component: Program7,
                    file: {
                        size: "601kb",
                        pages: "14",
                    },
                    subitems: [
                        {
                            id: "39",
                            title: "Ondersteuning en facilitering van de energietransitie",
                        },
                        {
                            id: "40",
                            title: "Wijkgebonden energiestrategie voor Leuven",
                        },
                        {
                            id: "41",
                            title: "Hernieuwbare warmte op gebouwniveau",
                        },
                        {
                            id: "42",
                            title: "Collectieve warmteproductie",
                        },
                        {
                            id: "43",
                            title: "Lokale biomassa voor energieproductie",
                        },
                        {
                            id: "44",
                            title: "Warmtedistributie",
                        },
                        {
                            id: "45",
                            title: "Fotovoltaïsche productie",
                        },
                        {
                            id: "46",
                            title: "Windturbines",
                        },
                        {
                            id: "47",
                            title: "Lokale elektriciteitsdistributie en -opslag",
                        },
                    ],
                    parallax: [
                        {
                            image: 'P7-layer0.jpg',
                            depth: 0.08,
                        },
                        {
                            image: 'P7-layer1.png',
                            depth: 0.15,
                        },
                    ],
                }
            ]
        },
        {
            id: "duurzame-consumptie",
            title: "Duurzame consumptie",
            items: [
                {
                    number: 8,
                    id: "duurzaam-en-gezond-eten",
                    title: "Duurzaam en gezond eten",
                    component: Program8,
                    file: {
                        size: "938kb",
                        pages: "10",
                    },
                    subitems: [
                        {
                            id: "48",
                            title: "Promoten van duurzame en gezonde voeding",
                        },
                        {
                            id: "49",
                            title: "Minder voedselverspilling en verpakkingsmaterialen bij voedingsproducten",
                        },
                        {
                            id: "50",
                            title: "Vergroten van de participatieve, duurzame voedselproductie in de stadsregio Leuven",
                        },
                        {
                            id: "51",
                            title: "Verhogen eco-efficiëntie van voedselproductie",
                        },
                        {
                            id: "52",
                            title: "Stimuleren van innovatie voor duurzame landbouw en voeding",
                        },
                    ],
                    parallax: [
                        {
                            image: 'P8-layer0.jpg',
                            depth: 0,
                        },
                        {
                            image: 'P8-layer1.jpg',
                            depth: 0.08,
                        },
                        {
                            image: 'P8-layer2.png',
                            depth: 0.09,
                        },
                        {
                            image: 'P8-layer3.png',
                            depth: 0.10,
                        },
                        {
                            image: 'P8-layer4.png',
                            depth: 0.13,
                        },
                    ],
                },
                {
                    number: 9,
                    id: "leuven-circulair",
                    title: "Leuven Circulair",
                    component: Program9,
                    file: {
                        size: "1455kb",
                        pages: "12",
                    },
                    subitems: [
                        {
                            id: "53",
                            title: "Circulair ondernemen in Leuven",
                        },
                        {
                            id: "54",
                            title: "Circulair Bouwen",
                        },
                        {
                            id: "55",
                            title: "Herstellen, delen en hergebruiken",
                        },
                        {
                            id: "56",
                            title: "Duurzaam en circulair consumeren",
                        },
                        {
                            id: "57",
                            title: "Kennis ontwikkelen en circulaire principes duurzaam verankeren in het beleid",
                        },
                    ],
                    parallax: [
                        {
                            image: 'P9-layer0.jpg',
                            depth: 0.08,
                        },
                        {
                            image: 'P9-layer1.png',
                            depth: 0.1,
                        },
                        {
                            image: 'P9-layer2.png',
                            depth: 0.15,
                        },
                    ],
                    copyright: "Raf Degeest",
                }
            ]
        },
        {
            id: "veerkrachtig-leuven",
            title: "Een groen en veerkrachtig leuven",
            items: [
                {
                    number: 10,
                    id: "groene-en-veerkrachtige-ruimte",
                    title: "Groene en veerkrachtige ruimte",
                    component: Program10,
                    file: {
                        size: "842kb",
                        pages: "12",
                    },
                    subitems: [
                        {
                            id: "59",
                            title: "Ruimtebeslag verminderen",
                        },
                        {
                            id: "60",
                            title: "Leuven maximaal vergroenen",
                        },
                        {
                            id: "61",
                            title: "Biodiversiteit in de gebouwde ruimte",
                        },
                        {
                            id: "62",
                            title: "CO₂-afvang en -opslag",
                        },
                        {
                            id: "63",
                            title: "Hitte-eilandeffect tegengaan",
                        },
                        {
                            id: "64",
                            title: "Klimaatrobuustheid bij hevige regenval en droogte",
                        },
                    ],
                    parallax: [
                        {
                            image: 'P10-layer0.jpg',
                            depth: 0,
                        },
                        {
                            image: 'P10-layer1.jpg',
                            depth: 0.08,
                        },
                        {
                            image: 'P10-layer2.png',
                            depth: 0.10,
                        },
                        {
                            image: 'P10-layer3.png',
                            depth: 0.12,
                        },
                        {
                            image: 'P10-layer4.png',
                            depth: 0.15,
                        },
                    ],
                    copyright: "Karl Bruninx",
                },
            ]
        },
        {
            id: "klimaattransitie",
            title: "De klimaattransitie mogelijk maken",
            items: [
                {
                    number: 11,
                    id: "governance-en-financieringen",
                    title: "Governance en financiering",
                    component: Program11,
                    file: {
                        size: "642kb",
                        pages: "12",
                    },
                    subitems: [
                        {
                            id: "65",
                            title: "De samenwerking tussen actoren intensiveren",
                        },
                        {
                            id: "66",
                            title: "Een slagkrachtig en integraal stedelijk klimaatbeleid",
                        },
                        {
                            id: "67",
                            title: "Intergemeentelijke samenwerking in de Leuvense stadsregio",
                        },
                        {
                            id: "68",
                            title: "Samenwerken met hogere overheden",
                        },
                        {
                            id: "69",
                            title: "Duurzaam ondernemen",
                        },
                        {
                            id: "70",
                            title: "Klimaatgericht innovatief ondernemerschap",
                        },
                        {
                            id: "71",
                            title: "Innovatieve financieringsmechanismen",
                        },
                        {
                            id: "72",
                            title: "Vzw Leuven 2030 structureel uitbouwen",
                        },
                    ],
                    parallax: [
                        {
                            image: 'P11-layer0.jpg',
                            depth: 0.08,
                        },
                        {
                            image: 'P11-layer1.png',
                            depth: 0.1,
                        },
                        {
                            image: 'P11-layer2.png',
                            depth: 0.15,
                        },
                    ],
                },
                {
                    number: 12,
                    id: "iedereen-betrekken-in-klimaattransitie",
                    title: "Iedereen betrekken in de klimaattransitie",
                    component: Program12,
                    file: {
                        size: "628kb",
                        pages: "8",
                    },
                    subitems: [
                        {
                            id: "73",
                            title: "Het partnerschap versterken & participatief werken",
                        },
                        {
                            id: "74",
                            title: "Brede maar gedifferentieerde sensibilisering",
                        },
                        {
                            id: "75",
                            title: "Jongeren mee-krijgen",
                        },
                        {
                            id: "76",
                            title: "Sociale rechtvaardigheid van klimaatmaatregelen",
                        },
                    ],
                    parallax: [
                        {
                            image: 'P12-layer0.jpg',
                            depth: 0.07,
                        },
                        {
                            image: 'P12-layer1.png',
                            depth: 0.11,
                        },
                        {
                            image: 'P12-layer2.png',
                            depth: 0.15,
                        },
                    ],
                },
                {
                    number: 13,
                    id: "kennisopbouw-en-monitoring",
                    title: "Kennisopbouw en monitoring",
                    component: Program13,
                    file: {
                        size: "586kb",
                        pages: "8",
                    },
                    subitems: [
                        {
                            id: "77",
                            title: "Dataverzameling en monitoring",
                        },
                        {
                            id: "78",
                            title: "Kennisopbouw en onderzoek",
                        },
                        {
                            id: "79",
                            title: "Kennisdeling",
                        },
                        {
                            id: "80",
                            title: "Actualisatie van de Roadmap",
                        },
                    ],
                    parallax: [
                        {
                            image: 'P13-layer0.jpg',
                            depth: 0,
                        },
                        {
                            image: 'P13-layer1.jpg',
                            depth: 0.06,
                        },
                        {
                            image: 'P13-layer2.png',
                            depth: 0.1,
                        },
                        {
                            image: 'P13-layer3.png',
                            depth: 0.12,
                        },
                        {
                            image: 'P13-layer4.png',
                            depth: 0.15,
                        },
                    ],
                    copyright: "Stijn Bollaert",
                },
            ]
        }
    ]
}
