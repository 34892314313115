import React from 'react';

import { useTransition, animated, config } from 'react-spring'

const TocImage = ({ id, url }) => {
    const transitions = useTransition(url, item => item, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: config.gentle,
    });

    return (
        <div className="toc-image sticky" data-desktop-observer-target data-id={id}>
            {transitions.map(({ item, props, key }) => (
                <animated.div
                    key={key}
                    className="toc-image__image"
                    style={{ ...props, backgroundImage: `url(${item})` }}
                />
            ))}
        </div>
    );
};

export default TocImage;
