import React from 'react';
import { Link } from 'react-router-dom';

import Glossary from '../../components/glossary/Glossary';

const Program1 = () => (
    <div className="block">
        <p>
            Het energieverbruik van onze woningen is verantwoordelijk voor ongeveer <strong>1/8<sup>ste</sup> van de Leuvense <Glossary
                term="CO₂e-uitstoot"
                definition="De uitstoot van broeikasgassen wordt regelmatig uitgedrukt in CO₂-equivalenten (CO₂e). CO₂ is immers niet het enige klimaatverstorende broeikasgas. Met de rekeneenheid CO₂e wordt de impact van de andere broeikasgassen vertaald naar CO₂-impact, zodat alles in één cijfer kan worden weergegeven."
            /></strong>. Een van de grootste uitdagingen ligt dus in het veel sneller renoveren (of vervangen) van de bestaande woningen. Momenteel is de renovatiegraad in Leuven net geen 1%, dat wil zeggen dat er jaarlijks ongeveer één woning per honderd woningen wordt gerenoveerd. Dit percentage willen we de komende jaren zien groeien tot minstens 3%. Uiteindelijk is het streefdoel om jaarlijks 1000 woningen te renoveren tot energiezuinige woonsten. De energie die dan nog nodig zal zijn voor het verwarmen en koelen van die woningen, en voor hun elektriciteitsverbruik, zal afkomstig zijn van hernieuwbare energiebronnen zoals zon, wind en bodem. Deze uitdaging vindt haar plaats onder <Link to="/groene-energie-opwekken">programma 7 – groene energie opwekken</Link>.
            </p>
        <p>
            Een duurzaam gerenoveerde woning heeft veel voordelen (een lagere energiefactuur, een hoger wooncomfort en een hogere vastgoedwaarde), maar de werken zijn complex. Woningeigenaars zijn met tienduizenden in Leuven en niet iedereen heeft de capaciteit, kennis of interesse om zijn/haar woning aan te pakken. Daarom focust het programma ‘woningen renoveren’ zich sterk op het actief benaderen en ondersteunen van woningeigenaars. Op die manier kan elke Leuvenaar met verbouwingsplannen gericht advies over energiemaatregelen in zijn of haar woning ontvangen. Daarnaast wordt ook samengewerkt met de bouwsector om de geplande versnelling van de renovaties ook hier voor te bereiden. Collectieve woningrenovatieprojecten waarbij een hele stadswijk in één groot project kan worden gerenoveerd (wat extra voordelen kan opleveren), en de sector van de huurwoningen hebben een eigen, specifieke aanpak nodig.
        </p>
    </div>
);

export default Program1;
