import React from 'react';

const Program7 = () => (
    <div className="block">
        <p>
            Om de klimaattransitie mogelijk te maken, is het nodig dat de Leuvense samenleving zoveel mogelijk groene energie zelf opwekt. Dit kan op eigen grondgebied, dankzij samenwerkingen met de buurgemeenten of door zelf mee te investeren in groene stroom en warmte elders in België. Hiervoor is nog heel wat ondersteuning en facilitering nodig, zoals aanpassing van regelgeving en fiscaliteit. Het heeft echter geen zin om hierop te wachten: heel wat stappen, zoals het inzetten op zonne- en windenergie of het uitbouwen van strategieën die per wijk de energietransitie aanpakken, kunnen nu al worden gezet of minstens voorbereid.
        </p>
    </div>
);

export default Program7;
