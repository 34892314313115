/* eslint-disable react/no-danger */

import React from 'react';
import PropTypes from 'prop-types';

const GlossaryPopover = ({
    title, definition, onClose,
}) => (
    <div className="glossary-popover">
        <button
            className="glossary-popover__close"
            onClick={onClose}
            type="button"
        >
            <span className="visually-hidden">Close</span>
            <svg className="icon-small" viewBox="0 0 18 18" role="presentation" focusable="false">
                <use xlinkHref="#icon-close" />
            </svg>
        </button>
        <dl id={title}>
            <dt className="glossary-popover__title">{title}</dt>
            <dd className="glossary-popover__text" dangerouslySetInnerHTML={{ __html: definition }} />
        </dl>
    </div>
);

GlossaryPopover.propTypes = {
    title: PropTypes.string.isRequired,
    definition: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default GlossaryPopover;
