import React from 'react';

const Footer = () => (
    <footer className="footer">
        <a href="https://www.leuven2030.be/" className="footer__branding">
            <svg width="160" height="75" className="footer__logo">
                <use xlinkHref="#logo-white"></use>
            </svg>
            <span className="footer__back">
                Naar de website
                <svg width="7" height="6" className="footer__back-svg">
                    <use xlinkHref="#arrow-right"></use>
                </svg>
            </span>
        </a>

        <nav className="footer__nav">
            <ul className="footer__links">
                <li className="footer__links-item">
                    <a href="https://www.leuven2030.be/over-ons">Over Leuven 2030</a>
                </li>
                <li className="footer__links-item">
                    <a href="https://www.leuven2030.be/">Verhalen</a>
                </li>
                <li className="footer__links-item">
                    <a href="https://www.leuven2030.be/oplossingen">Oplossingen</a>
                </li>
                <li className="footer__links-item">
                    <a href="https://www.leuven2030.be/onderzoek">Onderzoek</a>
                </li>
            </ul>
        </nav>

        <ul className="footer__social">
            <li>
                <a href="https://www.facebook.com/leuven2030" className="footer__social-link">
                    <svg  width="36" height="36" className="footer__social-icon facebook">
                        <use xlinkHref="#icon-facebook"></use>
                    </svg>
                </a>
            </li>
            <li>
                <a href="https://twitter.com/leuven2030" className="footer__social-link">
                    <svg width="36" height="30" className="footer__social-icon twitter">
                        <use xlinkHref="#icon-twitter"></use>
                    </svg>
                </a>
            </li>
            <li>
                <a href="https://www.linkedin.com/company/leuven2030/" className="footer__social-link">
                    <svg width="33" height="30" className="footer__social-icon linkedin">
                        <use xlinkHref="#icon-linkedin"></use>
                    </svg>
                </a>
            </li>
        </ul>
    </footer>
);

export default Footer;
