import React from 'react';

const IntroContent = () => (
    <div className="intro-content">
        <div className="intro-content__text">
            <div className="block">
                <h1 className="headline-1 intro-content__title">Roadmap<br />2025 • 2035 • 2050</h1>
                <h2 className="intro-content__subtitle headline-3">13 programma's naar een klimaatneutraal Leuven</h2>
            </div>
            <div className="block">
                <p>
                    The time is now. Nú moet de stap gezet worden van ‘doen wat kan’ naar ‘doen wat moet’. Dat impliceert een systemische verandering van de stad en de samenleving. Leuven heeft de ambitie en de verantwoordelijkheid om hierin een voortrekkersrol te spelen. De Roadmap 2025 · 2035 · 2050, opgesteld door Leuven 2030 en tal van experten, dient als leidraad om tegen 2050 het doel van een klimaatneutrale stad te realiseren. Dat deze uitdaging op véle schouders dient te worden gelegd, is meer dan ooit duidelijk. Daarom startte in september 2019 een professioneel team van programmaverantwoordelijken, die dit unieke plan zullen omzetten naar verdere concrete actie en impact op het terrein.
                </p>
            </div>
        </div>
    </div>
);

export default IntroContent;