import React from 'react';

import Glossary from '../../components/glossary/Glossary';

const Program2 = () => (
    <div className="block">
        <p>
            Het verwarmen van niet-woningen zoals kantoren, scholen of ziekenhuizen, en hun elektriciteitsverbruik, is verantwoordelijk voor ongeveer <strong>1/8<sup>ste</sup> van de Leuvense <Glossary
                term="CO₂e-uitstoot"
                definition="De uitstoot van broeikasgassen wordt regelmatig uitgedrukt in CO₂-equivalenten (CO₂e). CO₂ is immers niet het enige klimaatverstorende broeikasgas. Met de rekeneenheid CO₂e wordt de impact van de andere broeikasgassen vertaald naar CO₂-impact, zodat alles in één cijfer kan worden weergegeven."
            /></strong>. Een belangrijke uitdaging van de Leuvense klimaattransitie is dan ook om de verwarmings- en koelingsbehoefte van deze gebouwen drastisch te verminderen. Maar ook op het vlak van (elektrische) installaties – denk aan verlichtingssystemen, elektronica,… –  zijn er grote winsten te boeken. In het programma ‘niet-woningen renoveren’ wordt een aanpak uitgezet om gebouweigenaars van deze doorgaans grote gebouwen, te benaderen en te ondersteunen: in gezamenlijke of individuele projecten. Vele van die grote eigenaars, zoals Stad Leuven, KU Leuven, UZ Leuven en imec, zijn stichtend lid van de vzw Leuven 2030: zij engageren zich tot een versnelde inspanning en spelen een voorbeeldrol in de klimaattransitie.
        </p>
    </div>
);

export default Program2;
