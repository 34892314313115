import React, { useEffect, useRef } from 'react';

import Parallax from 'parallax-js';

const IntroImage = ({ id }) => {
    const introImage = useRef(null);

    useEffect(() => {
        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        
        if (introImage) {
            const visualParralax = new Parallax(introImage.current, { // eslint-disable-line no-unused-vars
                relativeInput: true,
                hoverOnly: true,
                inputElement: introImage.current.parentElement,
                selector: '.intro-image__parallax',
                limitY: 10,
                scalarX: isMobile ? 50 : 10,
                scalarY: isMobile ? 50 : 10,
            });
        }
    }, [introImage]);

    return (
        <div className="intro-image" ref={introImage} data-desktop-observer-target data-id={id}>
            <div className="intro-image__parallax" data-depth="0.05">
                <div className="intro-image__image" style={{ backgroundImage: `url('./parallax/intro-layer0.jpg')` }} />
            </div>
            <div className="intro-image__parallax" data-depth="0.045">
                <div className="intro-image__image" style={{ backgroundImage: `url('./parallax/intro-layer1.png')` }} />
            </div>
            <div className="intro-image__parallax" data-depth="0.12">
                <div className="intro-image__image" style={{ backgroundImage: `url('./parallax/intro-layer2.png')` }} />
            </div>
            <div className="intro-image__parallax" data-depth="0.15">
                <div className="intro-image__vlinder intro-image__image" style={{ backgroundImage: `url('./parallax/intro-layer3.png')` }} />
            </div>
            <div className="intro-image__parallax" data-depth="0.07">
                <div className="intro-image__zeppelin intro-image__image" style={{ backgroundImage: `url('./parallax/intro-layer4.png')` }} />
            </div>
            <div className="intro-image__parallax" data-depth="0.05">
                <div className="intro-image__birds intro-image__image" style={{ backgroundImage: `url('./parallax/intro-layer5.png')` }} />
            </div>
        </div>
    );
};

export default IntroImage;
