import React from 'react';

import { Link } from 'react-router-dom';

import { getProgramByID } from '../../helpers';

const Profile = ({ id, name, image, programs, isAccent, description, email, showEmail }) => (
    <div className={`profile${isAccent ? ' is-accent' : ''}`}>
        <div className="profile__image">
            <img src={`./profiles/${image}`} alt={name} />
        </div>
        <div className="profile__info">
            <div className="profile__name">
                {name}
            </div>
            {showEmail && email && (
                <span className="profile__meta">
                    <a href={`mailto:${email}`}>{email}</a>
                </span>
            )}
            {!showEmail && description && (
                <span className="profile__meta">
                    {description}
                </span>
            )}
            {!showEmail && programs && Boolean(programs.length) && (
                <ul className="profile__meta">
                    {programs.map(program => (
                        <li key={program} className="profile__item">
                            <Link to={`/${program}`}>
                                {getProgramByID(program).title}
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    </div>
);

export default Profile;
