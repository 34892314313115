import React, { useEffect, useRef } from 'react';
import { debounce } from 'debounce';

const Homepage = ({ history, location, routes }) => {
    const scrollListener = useRef(null);
    const observer = useRef(null);

    const scrollToActiveItem = (id) => {
        const element = document.querySelector(`#${id}`);

        if (element) {
            const top = element.getBoundingClientRect().top + window.pageYOffset;

            window.scrollTo(0, top)
        }
    }

    const initScrollListener = (location, history) => {
        const options = {
            threshold: 0.51,
        };

        const newObserver = new IntersectionObserver((entries, observer) => { 
            entries.forEach(entry => {
                if (entry.isIntersecting && entry.target.dataset.id) {
                    if (location.pathname !== `/${entry.target.dataset.id}`) {
                        history.replace(`/${encodeURI(entry.target.dataset.id)}`);
                    }
                
                    observer.unobserve(entry.target);
                }
            });
        }, options);

        observer.current = newObserver;

        const listener = debounce(() => {
            routes.forEach((item) => {
                const target = document.querySelector(`#${item.id} [data-desktop-observer-target]`);
                
                if (!target) {
                    return;
                }

                if (observer.current) {
                    observer.current.observe(target);
                }
            });
        }, 500)
        
        window.addEventListener('scroll', listener);

        scrollListener.current = listener;
    };

    useEffect(() => {
        if (history.action !== 'POP' && history.action !== 'PUSH') {
            return;
        }

        if (scrollListener.current) {
            window.removeEventListener('scroll', scrollListener.current);
        }

        if (observer.current) {
            routes.forEach((item) => {
                const target = document.querySelector(`#${item.id} [data-desktop-observer-target]`);
                
                if (!target) {
                    return;
                }

                if (observer.current) {
                    observer.current.unobserve(target);
                }
            });

            observer.current.disconnect();
        }

        const id = location.pathname.replace('/', '');

        if (id) {
            scrollToActiveItem(id);
        }

        setTimeout(() => {
            initScrollListener(location, history);
        }, 300);
    });

    return (
        <React.Fragment />
    )
}
  
export default Homepage;
