import React from 'react';

const Program9 = () => (
    <div className="block">
        <p>
            We leven in een wereld waarin het weggooien van materialen, onze grondstoffen, de normaalste zaak van de wereld is. Veel materialen, waar eigenlijk best nog veel mee te doen is, belanden op de steeds groter wordende afvalberg. Het produceren en verwerken van deze materialen zorgt voor een groot aandeel in de Leuvense CO₂-uitstoot. In plaats van het storten en verbranden van dit ‘afval’ kunnen we deze materialen eigenlijk perfect hergebruiken. Op dit moment functioneert een stad vaak nog vooral als een lineair systeem. Er gaan verschillende stromen, zoals biomassa (voedsel, hout, …), energie (fossiele brandstoffen, …), mineralen (metalen, bouwmaterialen, …), water (drinkwater, …) en afgewerkte goederen de stad in. Deze worden omgezet in infrastructuur zoals gebouwen of in allerlei producten. Vervolgens ontstaan er uitgaande stromen zoals warmteoverschotten, afvalstromen, producten, … In zo’n lineair systeem gaan er dus heel wat kostbare grondstoffen verloren.
        </p>
        <p>
            Als je echter naar een stad kijkt als een soort bibliotheek van materialen, dan kijk je ineens ook anders naar een gebouw. Materialen in je stad houden, ze terughalen uit infrastructuur alsof de stad een ‘mijn’ is of op korte afstand van je stad produceren, zorgt ervoor dat er veel minder grondstoffen verloren gaan. Zo kunnen we gecreëerde waarde in onze stad houden en zijn we minder afhankelijk van anderen. Het alternatief voor een stad als een lineair systeem is dus een circulaire stad. Een circulaire stad is een belangrijke hefboom om de Leuvense voetafdruk te verkleinen en om minder broeikasgassen te produceren.
        </p>
        <p>
            In het nastreven van een circulaire stad komen heel wat elementen samen: energie zoveel mogelijk lokaal produceren, voedsel zoveel mogelijk lokaal produceren, compact en aanpasbaar bouwen, delen van kennis en data, kiezen voor duurzame mobiliteit, bewaren van water, ontwikkelen van een robuust netwerk van groene elementen, stimuleren van delen, repareren, hergebruik en recyclage, stimuleren van vernieuwende circulaire ondernemers en werkgelegenheid, verankeren van circulair denken in het algemeen beleid van de lokale overheid, ...
        </p>
        <p>
            Leuven 2030 en stad Leuven slaan de hand in elkaar om Leuven de volgende jaren stap voor stap omvormen tot circulaire stad. We kiezen voluit voor een circulaire toekomst die enorm veel kansen biedt op vlak van economische en maatschappelijke innovatie.
        </p>
    </div>
);

export default Program9;
