import React, { useState, useEffect } from 'react';
import { Manager, Reference, Popper } from 'react-popper';

import GlossaryPopover from './GlossaryPopover';

const Glossary = ({ term, definition }) => {
    const [open, setOpen] = useState(false);
    let timeout;

    useEffect(() => {
        window.addEventListener('keydown', closeOnEscape);
    }, []); // eslint-disable-line

    const togglePopover = (event) => {
        event.preventDefault();

        (open ? closePopover : openPopover)();
    }

    const openPopover = () => {
        clearTimeout(timeout);

        setOpen(true);
    }

    const closePopover = (event) => {
        if (event) {
            event.preventDefault();
        }

        timeout = setTimeout(() => {
            setOpen(false);
        }, 300);
    }

    const closeOnEscape = (event) => {
        if (event.keyCode === 27) {
            closePopover();
        }
    }

    return (
        <Manager>
            <button
                className="glossary__btn"
                type="button"
                aria-expanded={open ? 'true' : 'false'}
                onClick={togglePopover}
            >
                <Reference>
                    {({ ref }) => (
                        <React.Fragment>
                            <span className="glossary__term">
                                {term}
                            </span>
                            <span className="glossary__info" ref={ref}>
                                <svg width="6" height="10" role="presentation" focusable="false">
                                    <use xlinkHref="#icon-question-mark" />
                                </svg>
                            </span>
                        </React.Fragment>
                    )}
                </Reference>
            </button>
            {open &&
                <Popper placement="bottom" positionFixed>
                    {({
                        ref, placement, popperProps, style, arrowProps,
                    }) => (
                        <div
                            className="glossary-popover-wrapper"
                            ref={ref}
                            style={style}
                            data-placement={placement}
                            {...popperProps}
                            onMouseEnter={openPopover}
                            onMouseLeave={closePopover}
                            onFocus={openPopover}
                            onBlur={closePopover}
                        >
                            <GlossaryPopover
                                title={term}
                                definition={definition}
                                onClose={closePopover}
                            />
                            <div
                                className="glossary-popover__arrow"
                                ref={arrowProps.ref}
                                style={arrowProps.style}
                                data-placement={placement}
                            />
                        </div>
                    )}
                </Popper>
            }
        </Manager>
    );
};

export default Glossary;
