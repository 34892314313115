import React, { useState, useEffect, useRef } from 'react';

import TocImage from './TocImage';
import TocItem from './TocItem';
import TocIconItem from './TocIconItem';

const TableOfContents = ({ id, data }) => {
    const [ready, setReady] = useState(false);
    const tocRef = useRef(null);

    const hideTocClose = () => {
        const tocClose = document.querySelector('[data-toc-close]');

        if (tocClose) {
            tocClose.classList.remove('is-visible');
        }
    }

    useEffect(() => {
        const io = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setReady(true);
                    
                    return;
                }

                hideTocClose();
            });
        }, {
            threshold: .01,
        });

        io.observe(tocRef.current);
    }, [tocRef]);

    const [imageURL, setImageURL] = useState('./parallax/toc.jpg');
    const [previousImageURL, setPreviousImageURL] = useState(imageURL);

    const changeImageOnHover = (url) => {
        setPreviousImageURL(imageURL);
        setImageURL(url);
    }

    const resetImage = () => {
        setPreviousImageURL('./parallax/toc.jpg');
        setImageURL('./parallax/toc.jpg');
    }

    const goBack = () => {
        window.history.back();
    }

    return (
        <div id={id} className={`toc${ready ? ' is-ready' : ''}`} ref={tocRef} data-toc>
            <TocImage
                id={id}
                placeholder={previousImageURL}
                url={imageURL}
            />
            <div className="toc-index">
                <button type="button" onClick={goBack} className="toc-close" data-toc-close>
                    <svg width="14" height="14" className="toc-close__icon">
                        <use xlinkHref="#icon-close"></use>
                    </svg>
                    <span className="toc-close__label">
                        Sluiten
                    </span>
                </button>
                <nav>
                    <ul className="toc-index__list">
                        <li>
                            <div className="toc-index__label is-accent">
                                <span>Start</span>
                            </div>

                            <ul>
                            {data.pagesBefore.map(item => {
                                if (!item.tocTitle) {
                                    return null;
                                }

                                return(
                                    <li key={item.id}>
                                        <TocIconItem 
                                            url={`/${item.id}`}
                                            title={item.tocTitle}
                                            icon={item.tocIcon}
                                        />
                                    </li>
                                );
                            })}
                            </ul>
                        </li>

                        <li className="toc-index__list">
                            {data.themes.map(theme => (
                                <React.Fragment>
                                    <div className="toc-index__label">
                                        <span>{theme.title}</span>
                                    </div>
                                    <ul key={theme.id}>
                                        {theme.items.map(item => (
                                            <li
                                                key={item.id}
                                                onMouseEnter={() => changeImageOnHover(`./parallax/${item.parallax[0].image}`)}
                                                onMouseLeave={resetImage}
                                            >
                                                <TocItem 
                                                    number={item.number}
                                                    url={`/${item.id}`}
                                                    title={item.title}
                                                />
                                            </li>
                                        ))}
                                    </ul>
                                </React.Fragment>
                            ))}
                        </li>
                        
                        <li>
                            <div className="toc-index__label is-accent">
                                <span>Algemeen</span>
                            </div>

                            <ul>
                                {data.pagesAfter.map(item => {
                                    if (!item.tocTitle) {
                                        return null;
                                    }

                                    return(
                                        <li key={item.id}>
                                            <TocIconItem 
                                                url={`/${item.id}`}
                                                title={item.tocTitle}
                                                icon={item.tocIcon}
                                            />
                                        </li>
                                    );
                                })}
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default TableOfContents;
