import React from 'react';

import { Link } from 'react-router-dom';

const Explainer = ({ id }) => (
    <React.Fragment>
        <article id={id} className="page is-bluegreen explainer">
            <div className="page__inner is-flex" data-desktop-observer-target data-id={id}>
                <div className="page__left is-narrow">
                    <h1 className="headline-1">Waarom deze  roadmap?</h1>
                </div>
                <div className="page__right is-wide">
                    <p>
                        De voorbije jaren organiseerde of ondersteunde Leuven 2030 verschillende projecten en initiatieven die bijdragen aan een duurzame toekomst met minder uitstoot. Ondanks de mooie inzet van de Leuvense samenleving, geeft onze CO₂-monitoring aan dat de CO₂-emissies in Leuven niet snel genoeg dalen om een klimaatneutrale toekomst waar te maken. Hoog tijd om een versnelling hoger te schakelen! Leuven 2030 heeft daarom, samen met een grote groep experten uit uiteenlopende disciplines, een Roadmap opgesteld. Die Roadmap 2025 · 2035 · 2050 laat zien voor welke grote uitdagingen we staan en toont een tijdlijn met de noodzakelijke stappen tegen 2025, 2035 en 2050 om het doel van een klimaatneutrale stad te realiseren. In de Roadmap is een cruciale rol weggelegd voor iedere inwoner, ieder bedrijf, iedere kennisinstelling en iedere overheid. Hun inzet, zowel individueel als in samenwerking, is cruciaal om naar een gezond, leefbaar en klimaatneutraal Leuven te kunnen evolueren.
                    </p>
                </div>
            </div>
        </article>
        <article className="page is-blue explainer">
            <div className="page__inner is-flex">
                <div className="page__left is-narrow">
                    <h1 className="headline-1">Hoe zetten we deze Roadmap om in de praktijk?</h1>
                </div>
                <div className="page__right is-wide">
                    <p>
                        Om een ambitieus plan als deze Roadmap om te zetten in de praktijk kan niets aan het toeval overgelaten worden en is de inzet van vele partners noodzakelijk. Daarom werd voor elk van de 13 programma’s in de Roadmap een <Link to="/verantwoordelijken">programmaverantwoordelijke</Link> aangesteld die, alleen of in duo, de uitbouw van een of meerdere programma’s zal ondersteunen. Hij/zij doet dit in de eerste plaats door op zoek te gaan naar werfleiders en partners om samen te werken op elk van de klimaatwerven in zijn/haar programma.  Daarnaast ondersteunen de <Link to="/verantwoordelijken">programmaverantwoordelijken</Link> deze samenwerkingsverbanden in het uitwerken van actie- en investeringsprogramma’s en in het omzetten van de geformuleerde doelstellingen naar concrete actie en impact op het terrein.
                    </p>
                    <p>
                        Voor de uitbouw van de programma’s maakt Leuven 2030 zo veel mogelijk gebruik van de expertise binnen het eigen netwerk. Verschillende partners als Stad Leuven, KU Leuven, Fluvius, Provincie Vlaams-Brabant, Rikolto, Kamp C, … hebben nu reeds – hetzij gratis, hetzij tegen vergoeding – sterke profielen vrijgemaakt om de 13 programma’s uit de startblokken te laten schieten. We rekenen erop om in de toekomst nog meer expertise uit ons netwerk te kunnen aanspreken. 
                    </p>
                </div>
            </div>
        </article>
    </React.Fragment>
);

export default Explainer;
