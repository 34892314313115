import React from 'react';

import { padNumber } from '../../helpers';

const SubProgram = ({ number, title }) => {
    return (
        <div className="subprogram">
            <div className={`subprogram-number subprogram-number--${number % 4 + 1}`}>
                <span className="subprogram-number__label">Werf </span>
                <span  className="subprogram-number__value">
                    {padNumber(number)}
                </span>
            </div>
            <p className="subprogram__title">
                {title}
            </p>
        </div>
    );
};

export default SubProgram;
