import React, { useState, useEffect } from 'react';
import { Link } from "react-scroll";

import IntroContent from './IntroContent';
import IntroImage from './IntroImage';

const Intro = ({ id }) => {
    const [ready, setReady] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setReady(true);
        }, 500);
    }, []);

    return (
        <div id={id} className={`intro${ready ? ' is-ready' : ''}`}>
            <div className="intro__inner">
                <IntroContent />
                <IntroImage id={id} />
            </div>

            <div className="intro__top">
                <a href="https://www.leuven2030.be/" className="intro__back">
                    <svg width="7" height="6" className="intro__back-svg">
                        <use xlinkHref="#arrow-left"></use>
                    </svg>
                    Naar de website
                </a>

                <svg width="160" height="75" className="intro__logo">
                    <use xlinkHref="#logo-color"></use>
                </svg>
            </div>

            <Link
                to="inhoud"
                smooth={true}
                duration= {300}
                className="intro__down"
            >
                <span className="intro__down-bg">
                    <svg width="22" height="27" className="intro__down-svg1">
                        <use xlinkHref="#arrow-down"></use>
                    </svg>
                    <svg width="22" height="27" className="intro__down-svg2">
                        <use xlinkHref="#arrow-down"></use>
                    </svg>
                </span>
            </Link>
        </div>
    );
};

export default Intro;
 