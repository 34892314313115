import React from 'react';

const Program10 = () => (
    <div className="block">
        <p>
            Planten, water en de natuur in het algemeen spelen een heel belangrijke rol in een klimaatneutrale toekomst. Planten nemen niet enkel CO₂ op uit de lucht, maar brengen nog een aantal andere voordelen met zich mee. Planten en bomen hebben bijvoorbeeld een positief effect op onze gezondheid, onder andere doordat zij de luchtkwaliteit bevorderen.
        </p>
        <p>
            Een grote uitdaging voor de klimaattransitie is om Leuven maximaal te vergroenen zodat de stad een grotere weerstand heeft bij hevige regenval en droogte. Dit kan dankzij het aanplanten en beschermen van bomen en de realisatie van nieuwe groene ruimtes in en buiten de stad, op publiek en privaat terrein. Door netto geen open ruimte meer te bebouwen of te verharden zonder compensatie en bestaande bebouwing in perifere locaties af te bouwen tegen 2030, kan Leuven het ruimtebeslag verminderen. 
        </p>
        <p>
            Idealiter is de betonstop in Leuven al in 2025 een feit. Door het gebruik van donkere materialen zoals asfalt en baksteen, die veel warmte vasthouden, ligt de temperatuur in onze dichtbebouwde stad hoger dan op het groenere platteland. Om dit hitte-eilandeffect tegen te gaan is het, naast bovenstaande maatregelen, ook belangrijk om te zorgen voor voldoende kwaliteitsvolle waterlopen in de stad. Stromend water tempert het hitte-eilandeffect door veel warmte op te nemen en de stad uit te voeren.  Ook het vergroenen van versteende ruimtes (pleinen, parkeerterreinen, speelplaatsen,…) en gebouwen (groendaken, gevelgroen,…) draagt bij aan een groene en veerkrachtige stad. Op die manier vergroten we bovendien de biodiversiteit in de reeds gebouwde ruimtes. 
        </p>
        <p>
            Daarnaast zet Leuven waar mogelijk in de toekomst best in op de afvang en opslag van CO₂ (in biomassa of bodem) en proefprojecten voor innovatieve technieken om CO₂ uit de atmosfeer op te slaan in materialen. 
        </p>
    </div>
);

export default Program10;
