import React from 'react';

import Glossary from '../../components/glossary/Glossary';

const Program13 = () => (
    <div className="block">
        <p>
            Kennisopbouw, kennisdeling en monitoring zijn essentieel om het pad van de Roadmap te blijven bewaken en een continu leerproces te garanderen. Deze Roadmap is een momentopname, gebaseerd op de huidige stand van de kennis en inzichten. Voor heel wat thema’s is er bovendien nog nood aan bijkomend onderzoek en nieuwe pilootprojecten. De uitrol van de Roadmap is zelf ook een leertraject: niet alleen voor Leuven, maar ook voor andere steden en gemeenten in en buiten Vlaanderen die aan Leuven een voorbeeld kunnen nemen. De impact en de doeltreffendheid van de maatregelen, maar ook de organisatie van de klimaattransitie, zal in dat traject continu worden geëvalueerd en geoptimaliseerd. Een voortdurende aandacht voor innovaties en nieuwe inzichten uit binnen- en buitenland moet verzekeren dat nieuwe oplossingen niet over het hoofd worden gezien.
        </p>
        <p>
            Leuven, een stad die innovatie en kennisopbouw diep in haar DNA draagt, zal een voorlopersrol in de klimaattransitie opnemen. Door eigen onderzoek en innovatie, via de universiteit, de talrijke kennispartners in de stad, de vzw Leuven 2030 en Leuven MindGate, kan Leuven zelf actief bijdragen aan de kennisopbouw. Monitoring en een correcte rapportering van de reële <Glossary
                term="CO₂e-uitstoot"
                definition="De uitstoot van broeikasgassen wordt regelmatig uitgedrukt in CO₂-equivalenten (CO₂e). CO₂ is immers niet het enige klimaatverstorende broeikasgas. Met de rekeneenheid CO₂e wordt de impact van de andere broeikasgassen vertaald naar CO₂-impact, zodat alles in één cijfer kan worden weergegeven."
            /> wordt cruciaal om de voortgang van de klimaattransitie te kunnen opvolgen. Welke resultaten worden geboekt? Welke stakeholders werden al bereikt? Waar loopt het goed en minder goed? Met deze inzichten wordt de Roadmap een ‘levend document’, dat de volgende jaren stelselmatig zal worden geactualiseerd, gedreven door de monitoring en verrijkt met nieuwe inzichten.
        </p>
    </div>
);

export default Program13;
