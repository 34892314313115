import React, { useState, useRef, useEffect } from 'react';

import ProgramHeading from './ProgramHeading';
import ProgramImage from './ProgramImage';

import SubProgram from '../subprogram/SubProgram';
import Profile from '../profiles/Profile';
import CTA from '../cta/CTA';

import { getProfilesByProgramID } from '../../helpers';

const Program = ({ id, number, label, title, component, file, parallax, copyright, subitems }) => {
    const [reloadImage, setReloadImage] = useState(false);
    const [ready, setReady] = useState(false);
    const programRef = useRef(null);

    const profiles = getProfilesByProgramID(id);
    const ProgramComponent = component;

    useEffect(() => {
        const io = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                setReloadImage(false);

                if (entry.isIntersecting) {
                    setTimeout(() => {
                        setReady(true);
                        setReloadImage(true);
                    }, 300);
                }
            });
        });

        io.observe(programRef.current);
    }, [programRef]);

    return (
        <article id={id} className={`program${ready ? ' is-ready' : ''}`} ref={programRef}>
            <ProgramImage
                id={id}
                ready={reloadImage}
                loadedOnce={ready}
                parallax={parallax}
                copyright={copyright}
            />
            <div className="program-content">
                <div className="block">
                    <ProgramHeading
                        number={number}
                        label={label}
                        title={title}
                    />
                </div>
                
                <ProgramComponent />
               
                {Boolean(profiles.length) && (
                    <div className="block">
                        {profiles.length === 1 ? (
                            <p><strong>Wil je meer weten of ben je geïnteresseerd om mee te werken aan dit programma? Contacteer de programmaverantwoordelijke. </strong></p>
                        ) : (
                            <p><strong>Wil je meer weten of ben je geïnteresseerd om mee te werken aan dit programma? Contacteer een van de programmaverantwoordelijken.</strong></p>
                        )}

                        <div className="program-profiles">
                            {profiles.map(profile => (
                                <Profile key={profile.id} {...profile} showEmail />
                            ))}
                        </div>
                    </div>
                )}

                {subitems && Boolean(subitems.length) && (
                    <div>
                        <hr />
 
                        <h2 className="headline-2 is-blue is-center">
                            {subitems.length} werven 
                            <span className="subprograms__subtitle">Met deze klimaatwerven gaan we dit programma realiseren</span>
                        </h2>
                        <ul className="subprograms">
                            {subitems.map(item => (
                                <li key={item.id}>
                                    <SubProgram
                                        number={item.id}
                                        {...item}
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                <hr />

                <CTA
                    image={`./images/program${number}.png`}
                    text={`Download programma ${number}: <strong>${title}</strong> met een volledige beschrijving van alle werven.`}
                    meta={`PDF • ${file.size} • ${file.pages} pagina's`}
                    url={`./pdf/L2030_Roadmap_Programma${number}.pdf`}
                    cta="Download"
                />

                <CTA
                    image="./images/roadmap.png"
                    text="Geïnteresseerd in <strong>de volledige roadmap</strong>?"
                    meta="PDF • 9MB • 182 pagina's"
                    url="./pdf/L2030_Roadmap.pdf"
                    cta="Download"
                    isAccent="true"
                />
            </div>
        </article>
    );
};

export default Program;
