import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import Parallax from 'parallax-js';

import { detectIE } from '../../helpers';

const ProgramImage = ({ id, loadedOnce, ready, parallax, copyright }) => {
    const [toLoadImages, setToLoadImages] = useState(parallax.length);

    const programImage = useRef(null);

    useEffect(() => {
        if (!loadedOnce) {
            return;
        }

        parallax.forEach((item) => {
            const img = new Image();

            img.onload = () => {
                setToLoadImages(prevValue => prevValue - 1);
            }

            img.src = `./parallax/${item.image}`;
        });
    }, [parallax, loadedOnce]);

    useEffect(() => {
        if (detectIE()) {
            return;
        }

        if (programImage && ready === true && toLoadImages === 0) {
            const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            
            const visualParralax = new Parallax(programImage.current, { // eslint-disable-line no-unused-vars
                pointerEvents: true,
                relativeInput: true,
                hoverOnly: true,
                inputElement: programImage.current.parentElement,
                selector: '.program-image__parallax',
                limitY: 10,
                frictionX: 0.05,
                frictionY: 0.05,
                scalarX: isMobile ? 30 : 10,
                scalarY: isMobile ? 30 : 50,
            });
        }
    }, [parallax, programImage, ready, toLoadImages]);

    if (parallax && parallax.length > 0) {
        return (
            <div className="program-image sticky" ref={programImage} data-desktop-observer-target data-id={id}>
                <div className="program-image__parallax" data-depth={parallax[0].depth} key={parallax[0].image}>
                    <div className="program-image__bg" style={{ backgroundImage: `url('./parallax/${parallax[0].image}')` }} />
                </div>

                {ready && toLoadImages === 0 && parallax.slice(1, parallax.length).map(item => {
                    return (
                        <div className="program-image__parallax" data-depth={item.depth} key={item.image}>
                            <div className="program-image__image" style={{ backgroundImage: `url('./parallax/${item.image}')` }} />
                        </div>
                    )
                })}
                <Link to="/intro" className="program-image__logo">
                    <span className="header__logo-bg" />
                    <svg width="160" height="76" className="header__logo-icon">
                        <title>Terug naar intro</title>
                        <use xlinkHref="#logo-white"></use>
                    </svg>
                </Link>
                {copyright && (
                    <p className="program-image__copyright">
                        &copy; {copyright}
                    </p>
                )}
            </div>
        );
    }

    return (
        <p>Loading...</p>
    )
};

export default ProgramImage;
