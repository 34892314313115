import React from 'react';

const ProgramHeading = ({ number, title, label }) => {
    return (
        <div className="program-heading">
            <div className="label">
                {label}
            </div>
            <h1 className="program-heading__title headline-1">
                {title}
            </h1>
            <div className="program-heading__number">
                <span>
                    {number < 10 ? 0 : ''}
                    {number}
                </span>
                <svg width="214" height="23" className="program-heading__number-shape">
                    <use xlinkHref="#number-shape"></use>
                </svg>
            </div>
        </div>
    );
};

export default ProgramHeading;
