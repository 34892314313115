import React from 'react';

const Program6 = () => (
    <div className="block">
        <p>
            Duurzame mobiliteit is noodzakelijk om de klimaatdoelstellingen te halen. Tegen 2035 dienen daarom alle voertuigen in Leuven emissievrij te rijden. Stadsbussen willen we graag al in 2025 elektrisch. Een uitgebreid netwerk van oplaadpunten voor deze voertuigen is uiteraard essentieel, evenals een uitdoofbeleid voor fossiel gedreven voertuigen. Elektrische fietsen, steps, deelauto’s, ... vormen ook een essentieel onderdeel van een versnelde omslag naar duurzame vormen van mobiliteit.
        </p>
    </div>
);

export default Program6;
