import SvgSprite from '../assets/svg-sprite.svg';

(async () => {
    const svgSprite = document.querySelector('.svg-sprite');

    if (svgSprite) {
        return;
    }

    const svgSpriteResponse = await fetch(SvgSprite, {
        credentials: 'include',
    });
    const svgSpriteBody = await svgSpriteResponse.text();

    const container = document.createElement('div');
    container.classList.add('svg-sprite');
    container.style.display = 'none';
    container.innerHTML = svgSpriteBody;
    document.body.insertBefore(container, document.body.childNodes[0]);
})();
