import React from 'react';

const CTA = ({ image, text, meta, url, cta, isAccent }) => (
    <div className={`cta${isAccent ? ' is-accent' : ''}`}>
        <div className="cta__image" style={{ backgroundImage: `url('${image}')`}} />
        <div className="cta__content">
            <p
                className="cta__text"
                dangerouslySetInnerHTML={{__html: text}}
            />
            <span className="cta__meta">{meta}</span>
            <a className="button cta__button" href={url} target="_blank" rel="noopener noreferrer">
                {cta}
            </a>
        </div>
    </div>
);

export default CTA;
